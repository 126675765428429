﻿.page-link {
    position: relative;
    display: inline-flex;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 10px 15px;
    color: $electric-blue;
    background-color: $white;
    text-decoration: none;
    cursor: pointer;
}

.page-link:hover,
.page-link:focus {
    color: $white;
    background-color: $electric-blue;
}

.page-link:focus {
    z-index: 3;
}

.page-link.active {
    z-index: 2;
    color: $white;
    background-color: $electric-blue;
}

.page-link.disabled {
    color: #6c757d;
    pointer-events: none;
}
