

.fls-allow-portal-accordion {
    &__dropdown-toggle {
        color: $dark-blue;
        background-color: transparent;
        border: none;
        width: 100%;
        text-align: left;
        padding: 0.6rem 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            padding-left: 0.75rem;
        }

        &--selected {
            background-color: $steel-blue-variant-3;
            border-radius: .4rem;

            span {
                font-family: 'ProximaNovaBold', Arial, sans-serif;
            }

            .fls-allow-portal-accordion__icon-container {
                transform: rotate(180deg)
            }
        }
    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 2rem;
            height: 2rem;
            stroke: $fls-blue;
        }
    }

    &__dropdown-item-container {
        position: relative;
        padding-left: 3rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 1.5rem;
        opacity: 1;
        max-height: 20rem;
        overflow-y: scroll;
        transition: max-height 0.15s ease-in-out, opacity 0.15s ease-in-out;
        background: $white;
        border-radius: 0 0 0.4rem 0.4rem;
        scrollbar-color: $steel-blue-variant-3 $white;

        &::-webkit-scrollbar {
            width: 10px;
            background: none;
            padding: 0.2rem;

            &-thumb {
                background: $steel-blue-variant-3;
                border-radius: 0.4rem;

                &:hover {
                    background: $steel-blue-variant-2;
                }
            }

            &-track {
                background: $white;
                box-shadow: none;
                margin: 1rem;
            }
        }

        &--hidden {
            padding-bottom: 0;
            max-height: 1px;
            opacity: 0;
            z-index: -1;
            margin-bottom: 0;
        }
    }

    &__dropdown-item {
        margin: 1rem 0;
    }
}
