﻿.pagination {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    position: center;
    margin-bottom: 3rem;
    margin-top: 0.5rem;

    & > .page-link {
        border-left: 1px solid #dee2e6;
    }

    & > .page-link ~ .page-link {
        border-left: none;
    }
}
