$table-data-border: 0.002px inset $fls-blue-variant-4;

.fls-data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 3rem;
    box-shadow: $shallow-box-shadow;

    &__allow-portal {
        height: 0px; //Fixes a silly height issue by causing this to scale out of control in the table. The table isnt actually 0 pixels tall
    }

    &__checkbox {
        width: 2rem;
        height: 2rem;
        margin: 0 1rem;
    }

    &__button {
        all: inherit;
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        outline: none;
        width: 100%;
        cursor: pointer;
    }


    &__caption {
        color: $dark-blue;
        padding: 0 0 1rem 1.2rem;
        text-align: left;
        font-size: 17px;
    }

    &__dropdown {
        width: auto;
        height: 2em;
        min-width: 5rem;
        text-align: center;
    }

    &__row {
        &:hover td {
            color: $electric-blue;
        }

        &:nth-child(even) {
            background-color: $light-grey-variant-1;
        }

        &:nth-last-child(1) {
            border-bottom: $table-data-border;
        }

        background-color: $white-variant-1;
    }

    &__header, &__cell {
        padding: 1.4rem 2rem 1.4rem 0;
        max-height: 2rem;

        &:last-child {
            text-align: left;
            padding-right: 1.6rem;
        }

        &:first-child {
            padding-left: 1.6rem;
        }
    }

    &__header {
        font-size: 1.3rem;
        background-color: $dark-blue;
        color: $white;
        text-align: left;

        &:first-child {
            border-radius: .4rem 0 0 0;
        }

        &:last-child {
            border-radius: 0 .4rem 0 0
        }
    }

    &__cell {
        padding: 1.2rem 2rem 1.2rem 0;

        &:last-child {
            text-align: left;
            padding-right: 1.6rem;
            border-right: $table-data-border;
        }

        &:first-child {
            border-left: $table-data-border;
        }

        &--button-container {
            .fls-data-table__edit-button-container {
                justify-content: end;
            }
        }

        &--align-right {
            text-align: right;
        }

        &--nobreak {
            white-space: nowrap;
        }
    }

    &__edit-button {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0.5rem 1.5rem;
        text-transform: capitalize;
        background-color: $grey-blue-variant-1;
        border-radius: 0.4rem;
        border: none;
        margin-left: 1rem;

        &:hover {
            background-color: $blue-variant-3;
        }

        p {
            padding-left: 0.65rem;
        }

        span {
            width: 1.8rem;
            height: auto;
        }
    }

    &__edit-button-container {
        display: flex;
    }

    &__edit-button-mini-modal {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: fit-content;
        display: flex;
        flex-direction: column;
        border-radius: .4rem;
        box-shadow: $prominent-box-shadow;
        display: none;
        background-color: $white;

        &--active {
            display: block
        }
    }

    &__edit-button-mini-modal-container {
        position: relative;
        display: flex;
        align-items: stretch;
    }

    &__edit-button-mini-modal-item {
        cursor: pointer;
        width: 100%;
        padding: 1rem 2.5rem;
        background-color: none;
        border: none;
        text-transform: capitalize;
        display: flex;
        align-items: center;

        p {
            padding-left: 0.5rem;
        }

        &:hover {
            background-color: $grey-blue-variant-1;
        }

        span {
            width: 2rem;
            height: 2rem
        }

        &:first-child {
            border-radius: .4rem .4rem 0 0;
        }

        &:last-child {
            border-radius: 0 0 .4rem .4rem;
        }

        &--delete {
            background-color: $attention-red;

            p {
                color: $white;
            }

            &:hover {
                background-color: rgba($attention-red, 0.9)
            }

            &:active, &:focus {
                background-color: rgba($attention-red, 0.8)
            }
        }
    }
}
