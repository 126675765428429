

//CSS derived from FLSmidth.com
.fls-main-logo {
    display: block;
    flex-shrink: 0;
    height: 6rem;
    position: relative;
    width: 14rem;

    &--footer {
        height: 7rem;
        width: 16rem;
    }

    img {
        display: block;
        height: auto;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 10rem;
    }

    &:before {
        -webkit-animation-delay: 1s;
        -webkit-animation-duration: 2s;
        -webkit-animation-name: glow;
        -webkit-animation-timing-function: ease-in-out;
        animation-delay: 1s;
        animation-duration: 2s;
        animation-name: glow;
        animation-timing-function: ease-in-out;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__box-logo {
        width: 9.5rem;
    }
}
