.fls-modal {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  backdrop-filter: blur(3px);

  &__background {
    background-color: $dark-blue;
    opacity: 0.6;
    height: 100%;
    width: 100%;
  }

  &__content {
    background-color: $white;
    border-radius: 0.2rem;
    box-shadow: $default-box-shadow;
    width: 50rem;
    min-height: 20rem;
    position: absolute;
    z-index: 101;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.75rem 3rem 2.75rem 3rem;
    display: flex;
    flex-direction: column;
    &--allow-portal {
      background-color: transparent;
    }
  }

  &__header {
    border-bottom: 1px solid $light-grey-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.5rem;
    color: $dark-blue;
    &--allow-portal {
      text-align: center;
      color: $white;
      background-color: $dark-blue;
    }
  }

  &__slot-container {
    &--allow-portal {
      margin: 2.5%;
      display: flex;
      .newpart-modal {
        border-right: 1px solid #ccc; /* Grey line */
        padding-top: 12px;
        min-width: 50%;
        font-size: 24px;
        height: 40px;
      }
    }
  }

  &__slot-component {
    padding: 2rem 0;
    &--allow-portal {
      background-color: $white;
      padding: 1rem 0;
    }
  }

  &__action-buttons {
    padding-top: 3rem;
    margin-top: auto;

    button {
      margin-right: 1.5rem;
      min-width: 12.5rem;
    }

    &--allow-portal {
      background-color: $white;
      padding-top: 0px;
      margin-top: 0px;

      &__onebutton {
        margin-left: 25% !important;
      }
      button {
        margin: 2px;
        width: 49%;
        min-width: 12.5rem;
        color: $white;
      }
      button:first-child {
        color: $white;
        border: 1px solid $attention-red;
        background-color: $attention-red;

        &:disabled {
          background-color: $attention-red-2;
        }

        &:hover {
          background-color: $attention-red-2;
        }

        &:active,
        &:focus {
          background-color: $attention-red-2;
        }
      }
      button:last-child {
        border: 1px solid blue;
        background-color: blue;

        &:disabled {
          background-color: $electric-blue-variant-3;
        }

        &:hover {
          background-color: $electric-blue-variant-2;
        }

        &:active,
        &:focus {
          background-color: $electric-blue-variant-1;
        }
      }
    }
  }

  &__fls-checkbox {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
    position: relative;
    margin-top: 25px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ .fls-checkbox__checkmark {
      border: none;
    }

    input:checked ~ .fls-checkbox__checkmark:after {
      display: block;
    }

    input:checked ~ .fls-checkbox__checkmark:before {
      display: none;
    }

    .fls-checkbox__checkmark:after,
    .fls-checkbox__checkmark:before {
      left: 1px;
      top: 2px;
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes animate-modal-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes animate-modal-blur {
  0% {
    backdrop-filter: blur(0px);
  }

  100% {
    backdrop-filter: blur(3px);
  }
}

@keyframes animate-modal-position {
  0% {
    top: 55%;
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}
.part-number-input {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;

  &__label {
    font-weight: bold;
    color: #002a5a; // Dark blue color
    margin-right: 1rem;
    white-space: nowrap; // Prevent text from wrapping
  }

  &__field {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    max-width: 200px; // Adjust width as needed
  }
}

.fls-allowportal-excel-modal {
  &__upload-area {
    display: inline-flex;
    margin-top: 20px;
    > span {
      font-size: 15px;
      margin-left: 15px;
      font-weight: bold;
      margin-top: 12px;
    }
  }
}
