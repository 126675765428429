// Foundation imports
@import './foundation/scss/foundation';
@import './foundation/scss/iconography';
@import './foundation/scss/normalize';
@import './foundation/scss/screen-sizing';
@import './foundation/scss/typography';
@import './foundation/scss/fonts';
@import './foundation/scss/loading';
@import './foundation/scss/other-buttons';

// Components
@import './components/InputFields/TextInput/TextInput';
@import './components/Button/Button';
@import './components/Modal/ModalComponents';
@import './components/Footer/Footer';
@import './components/DataTable/DataTable';
@import './components/DataTable/DataTable';
@import './components/DataTable/DataTable';
@import './components/DataTable/DataTable';
@import './components/NavMenu/NavMenu';
@import './components/TabsSelector/TabsSelector';
@import './components/InputFields/SelectInput/SelectInput';
@import './components/SparePartCard/SparPartCard';
@import './components/DataHealthTrafficLight/DataHealthTrafficLight';
@import './components/InputFields/Checkbox/Checkbox';
@import './components/Accordion/Accordion';
@import './components/AllowPortalAccordion/AllowPortalAccordion';
@import './components/Pagination/Pagination';
@import './components/PageLink/PageLink';
@import './components/ExternalLinks/ExternalLinks';

// Features
@import './features/SparePartDetail/SparePartDetail';
@import './features/SparePartSearch/SparePartSearch';
@import './features/SparePartFilter/SparePartFilter';
@import './features/SparePartCollection/SparePartSavedSearches';
@import './features/InPageError/InPageError';
@import './features/SystemDetails/SystemDetails';
@import './features/PricingAgreements/CustomerOverview/CustomerOverview';
@import './features/PricingAgreements/PartOverview/PartOverview';
@import './features/AllowPortal/AllowPortal';
@import './features/AllowPortal/Modals/AllowPortalModal';

// Other
html {
  font-size: 10px;
  background-color: $steel-blue-variant-5;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.25rem 0;
}

p {
  margin: 0.2rem 0;
}

.no-scroll {
  overflow: hidden;
}
