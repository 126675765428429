

@mixin fluid-size($attribute,$minsize,$maxsize,$minviewport,$maxviewport,$sign:'') {  
    #{$attribute}: #{$sign} calc(#{$minsize}px + (#{$maxsize} - #{$minsize}) * ((100vw - #{$minviewport}px) / (#{$maxviewport} - #{$minviewport}))); 

    @media screen and (min-width:#{$maxviewport}px){
        #{$attribute}: #{$maxsize}px;
    }

    @media screen and (max-width:#{$minviewport}px){
        #{$attribute}: #{$minsize}px;
    }
}



.fls-font {
    &__xsmall-regular {
        @include fluid-size('font-size', 12, 12, $mobile, $desktop);
        font-weight: 400;
        font-family: 'ProximaNovaRegular', Arial, sans-serif
    }

    &__small-regular {
        line-height: 1.8rem;
        @include fluid-size('font-size', 14, 14, $mobile, $desktop);
        font-weight: 400;
        font-family: 'ProximaNovaRegular', Arial, sans-serif
    }

    &__medium-regular {
        line-height: 1.8rem;
        @include fluid-size('font-size', 16, 16, $mobile, $desktop);
        font-weight: 400;
        font-family: 'ProximaNovaRegular', Arial, sans-serif
    }

    &__large-regular {
        line-height: 1.8rem;
        @include fluid-size('font-size', 20, 20, $mobile, $desktop);
        font-weight: 400;
        font-family: 'ProximaNovaRegular', Arial, sans-serif
    }

    &__xsmall-bold {
        @include fluid-size('font-size', 12, 12, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__small-bold {
        @include fluid-size('font-size', 12, 14, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__medium-bold {
        @include fluid-size('font-size', 14, 16, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__xsmall-title {
        @include fluid-size('font-size', 18, 20, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif;
    }

    &__xsmall-title--allow-portal {
        @include fluid-size('font-size', 18, 20, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif;
        margin: 25px;
    }

    &__small-title {
        @include fluid-size('font-size', 20, 24, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__medium-title {
        @include fluid-size('font-size', 20, 26, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__title {
        @include fluid-size('font-size', 28, 36, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__xl-title {
        @include fluid-size('font-size', 36, 68, $mobile, $desktop);
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif
    }

    &__xsmall-regular, &__small-regular, &__xsmall-bold, &__small-bold, &__medium-bold, &__title {
        color: $fls-blue;
    }

    &__error {
        color: $attention-red;
    }
}