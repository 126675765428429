

// This file is reserved for buttons not related to JSX components. E.g. the close button on modals


.fls-button {
    &__modal-close {
        position: relative; 
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding-right: 0.5rem;
        
        span {
            opacity: 0;
        }

        &:before, &:after {
            background-color: $fls-blue;
            content: "";
            height: 2px;
            left: 50%;
            position: absolute;
            top: 50%;
            width: 20px;
        }

        &:before {
            transform: translate3d(-50%,-50%,0) rotate(45deg);
        }

        &:after {
            transform: translate3d(-50%,-50%,0) rotate(-45deg);
        }
    }
}