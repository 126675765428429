

.fls-checkbox {
    display: flex;
    align-items: center;
    min-height: 2.25rem;
    position: relative;

    &__wrapper {
        cursor: pointer;    
    }
    
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__label {
        cursor: pointer;
        color: $dark-blue;
        padding-left: 3rem;
        display: block;
        padding-top: .3rem;

        span {
            opacity: 0.6;
        }
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.6rem;
        width: 1.6rem;
        background-color: transparent;
        
        &:before {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM2MF8xNzkxKSI+CjxwYXRoIGQ9Ik0xNyAwSDNDMS4zIDAgMCAxLjMgMCAzVjE3QzAgMTguNyAxLjMgMjAgMyAyMEgxN0MxOC43IDIwIDIwIDE4LjcgMjAgMTdWM0MyMCAxLjMgMTguNyAwIDE3IDBaTTE4IDE3QzE4IDE3LjYgMTcuNiAxOCAxNyAxOEgzQzIuNCAxOCAyIDE3LjYgMiAxN1YzQzIgMi40IDIuNCAyIDMgMkgxN0MxNy42IDIgMTggMi40IDE4IDNWMTdaIiBmaWxsPSIjMDYyNTQ5Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMzYwXzE3OTEiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
            position: absolute;
        }

        &:after {
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM1N18xNzg3KSI+CjxwYXRoIGQ9Ik0xOS41NjM1IDEuMTQ4NTFDMTkuMTgyNSAwLjc0ODUxMSAxOC42MTExIDAuNzQ4NTExIDE4LjIzMDEgMS4xNDg1MUw5LjM3MyAxMC40NDg1TDcuMTgyNTIgOC4xNDg1MUM2LjgwMTU3IDcuNzQ4NTEgNi4yMzAxNCA3Ljc0ODUxIDUuODQ5MTkgOC4xNDg1MUM1LjQ2ODI0IDguNTQ4NTEgNS40NjgyNCA5LjE0ODUxIDUuODQ5MTkgOS41NDg1MUw4LjcwNjMzIDEyLjU0ODVDOC44OTY4MSAxMi43NDg1IDkuMTgyNTIgMTIuODQ4NSA5LjM3MyAxMi44NDg1QzkuNTYzNDggMTIuODQ4NSA5Ljg0OTE5IDEyLjc0ODUgMTAuMDM5NyAxMi41NDg1TDE5LjU2MzUgMi41NDg1MUMxOS45NDQ0IDIuMTQ4NTEgMTkuOTQ0NCAxLjU0ODUxIDE5LjU2MzUgMS4xNDg1MVYxLjE0ODUxWiIgZmlsbD0iIzA2MjU0OSIvPgo8cGF0aCBkPSJNMTkgOUMxOC40IDkgMTggOS40IDE4IDEwVjE3QzE4IDE3LjYgMTcuNiAxOCAxNyAxOEgzQzIuNCAxOCAyIDE3LjYgMiAxN1YzQzIgMi40IDIuNCAyIDMgMkgxNEMxNC42IDIgMTUgMS42IDE1IDFDMTUgMC40IDE0LjYgMCAxNCAwSDNDMS4zIDAgMCAxLjMgMCAzVjE3QzAgMTguNyAxLjMgMjAgMyAyMEgxN0MxOC43IDIwIDIwIDE4LjcgMjAgMTdWMTBDMjAgOS40IDE5LjYgOSAxOSA5WiIgZmlsbD0iIzA2MjU0OSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzM1N18xNzg3Ij4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=');
            position: absolute;
            display: none;
        }
    }

    input:checked ~ .fls-checkbox__checkmark {
        border: none;    
    }

    input:checked ~ .fls-checkbox__checkmark:after {
        display: block
    }

    input:checked ~ .fls-checkbox__checkmark:before {
        display: none
    }

    .fls-checkbox__checkmark:after, .fls-checkbox__checkmark:before {
        left: 1px;
        top: 2px;
        width: 100%;
        height: 100%;
    }
    
}