

.fls-select {
    width: fit-content;
    min-width: 22rem;
    position: relative;
    border: $fls-blue-border;
    margin: 1.5rem 0;

    &:hover {
        border: $electric-blue-border;

        span {
            color: $electric-blue;
        }

        div svg {
            fill: $electric-blue;
        }
    }

    &__chevron svg {
        fill: $fls-blue;
    }

    &__selected {
        padding: 1.3rem 1.5rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content-container {
        top: 5.5rem;
        box-shadow: $prominent-box-shadow;
        background-color: $white;
        position: absolute;
        z-index: 10;
        min-width: 100%;
        width: auto;

        &:before {
            box-shadow: $default-box-shadow;
            content: "";
            top: -0.5rem;
            position: absolute;
            width: 1rem;
            height: 1rem;
            left: 45%;
            background-color: $white;
            transform: rotate(135deg);
        }

        &:after {
            content: "";
            top: 0.01rem;
            position: absolute;
            width: 3rem;
            height: 1rem;
            left: 37%;
            background-color: $white;
        }
    }

    &__content-list-item {
        padding: 0.85rem 1.5rem;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        text-align: left;

        &--selected {
            color: $electric-blue-variant-1;
        }

        &:hover {
            color: $electric-blue;
        }

        &:first-child {
            padding: 1.5rem 1.5rem 0.85rem 1.5rem;
        }

        &:last-child {
            padding: 0.85rem 1.5rem 1.5rem 1.5rem;
        }
    }

    &__chevron {
        padding-left: 2rem;
        width: 2rem;
        height: 2rem;

        &--rotated {
            padding-left: 0;
            transform: rotate(180deg);
        }
    }
}
