

.fls-text-input {
    display: flex;
    min-width: 50rem;

    &__allow-portal-restricted
    {
        min-width: 0rem;
    }

    &__allow-portal-add-part
    {
        width: 50%;
    }

    button {
        margin-left: 1rem;
    }
   
    &__input-container {
        background-color: $white;
        width: 100%;
        display: flex;
        margin-right: 1rem;
        height: 4.5rem;
        position: relative;

        &--regular {
            display: block;
            background-color: transparent;
            border: none;
            margin-right: 0;

            .fls-text-input__input-field {
                box-sizing: border-box;
                height: 4.4rem;
                margin-top: 0.5rem;
                padding: 0 1rem;
                background-color: $white;
                border: 1px solid $light-grey-1;
            } 
        }

        &--add-part {
            width: auto;
            .fls-text-input__input-field {
                box-sizing: border-box;
                margin-left: 1rem;
                height: 4.4rem;
                margin-top: 0.5rem;
                padding: 0 1rem;
                border: 1px solid $light-grey-1;
                width: 100%;
            } 
        }
    }
    
    &__icon {
        top: 0.9rem;
        position: absolute;
        padding: 0 1rem;
        right: 0;
    }
    
    &__input-field {
        padding-left: 2rem;
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        border: 1px solid $light-grey-1;

        &:focus {
            outline: none;
            color: $dark-blue;
            border: 1px solid $grey-blue-variant-2;
        }

        &:hover {
            color: $dark-blue;
            border: 1px solid $grey-blue-variant-2;
        }
    }
}
