
.fls-sp-collection {
    min-height: calc(100vh - 12rem);
    margin: 0;

    &__content-section {
        @include component-max-width;
        margin-top: 4rem;
    }

    .fls-sp-detail__heading-wrapper {
        padding-bottom: 2rem;
        padding-top: 2rem;
        margin: 0 auto;
        @include component-max-width;

        p {
            -webkit-line-clamp: 3;
            line-height: 1.4;
        }
    }

    .fls-sp-detail__heading-section {
        min-height: 13.5rem;
        padding-top: 5rem;
    }

    .fls-sp-detail__heading-background-logo {
        bottom: -2rem;
    }
}
