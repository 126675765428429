
$header-background-color: $steel-blue-variant-3;

.fls-sp-filter {
    background-color: $steel-blue-variant-5;

    .fls-sp-detail__heading-section--short {
        position: relative;
    }

    .fls-sp-search__input-section {
        top: 0;
        background-color: $header-background-color;
        padding-bottom: 3rem;
    }

    .fls-sp-search__input-container {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-top: 3rem;
    }

    .fls-sp-search__util-buttons-container {
        margin-top: 1.8rem;
        display: flex;
    }

    .fls-button {
        height: 4.5rem;
    }

    &__container {
        display: flex;
        max-width: $component-max-width;
        margin: 0 auto;
        margin-top: 3.5rem;
        padding: 0 4rem;
        height: 100%
    }

    &__filter-wrapper {
        margin-right: 3rem;
        width: 100%;
        max-width: 27rem;
        border-right: 0.15rem solid $fls-blue-variant-5;
    }

    &__filter-type-container {
        margin-bottom: 3rem;
        margin-right: 1rem;
        color: $dark-blue;

        h3 {
            font-size: 1.8rem;
            margin-bottom: 1.5rem;
        }
    }
}
