

.fls-button {
    text-decoration: none;
    border: none;
    padding: 1.4rem 2.5rem;
    color: $fls-blue;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
    cursor: pointer;
    height: fit-content;
    border: 1px solid $dark-blue;
    background-color: $white;

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding-right: .5rem;
    }

    &:disabled {
        span, div {
            opacity: 0.4;
        }

        cursor: default;
    }

    &:active, &:focus {
        background-color: $attention-blue;
    }

    &--primary {
        color: $white;
        border: 1px solid $attention-blue;
        background-color: $attention-blue;

        &:disabled {
            background-color: $electric-blue-variant-3;
        }

        &:hover {
            background-color: $electric-blue-variant-2
        }

        &:active, &:focus {
            background-color: $electric-blue-variant-1
        }
    }

    &--primary-with-icon {
        color: $white;
        border: 1px solid $electric-blue;
        background-color: $electric-blue;
        display: flex;
        align-items: center;

        &:disabled {
            border: 1px solid $electric-blue-variant-3;
            background-color: $electric-blue-variant-3;

            &:hover {
                background-color: $electric-blue-variant-3;
            }
        }

        &:hover {
            background-color: $electric-blue-variant-2
        }

        &:active, &:focus {
            background-color: $electric-blue-variant-1
        }
    }

    &--primary-with-icon-settings {
        border: none;
        background-color: $steel-blue-variant-5;
        display: flex;
        white-space: nowrap;
        align-items: center;
        padding: 0;

        &:hover {
            color: $electric-blue-variant-1;
        }

        &:active, &:focus {
            color: $electric-blue-variant-1;
            background-color: $steel-blue-variant-5
        }
    }

    &--primary-with-icon-outline {
        color: $fls-blue;
        border: 1px solid $fls-blue;
        background-color: $white;
        display: flex;
        align-items: center;

        div svg path {
            stroke: $fls-blue;
        }

        &:disabled {
            border: 1px solid $electric-blue-variant-5;
            background-color: $electric-blue-variant-5;

            &:hover {
                border: 1px solid $electric-blue-variant-5;
                background-color: $electric-blue-variant-5;

                span {
                    color: $fls-blue;
                }

                div svg path {
                    stroke: $fls-blue;
                }
            }
        }

        &:hover {
            border: $electric-blue-border;
            background-color: $white;

            span {
                color: $electric-blue;
            }

            div svg path {
                stroke: $electric-blue;
            }
        }

        &:active, &:focus {
            background-color: $steel-blue-variant-5;
        }
    }

    &--secondary {
        color: $white;
        border: 1px solid $dark-blue;
        background-color: $dark-blue;

        &:hover {
            background-color: $dark-blue-variant-2
        }

        &:active, &:focus {
            background-color: $dark-blue-variant-1
        }
    }

    &--confirm {
        color: $white;
        //changed to attention-blue from prior attention-green
        border: 1px solid $attention-blue;
        background-color: $attention-blue;

        &:hover {
            background-color: rgba($attention-blue, 0.9)
        }

        &:active, &:focus {
            background-color: rgba($attention-blue, 0.8)
        }
    }

    &--cancel {
        &:hover {
            color: $attention-blue;
            border: 1px solid $attention-blue;
        }
    }

    &--action {
        &:hover {
            color: $attention-blue;
            border: 1px solid $attention-blue;
        }

        &:active, &:focus {
            background-color: $white;
        }
    }

    &--delete {
        color: $white;
        border: 1px solid $attention-red;
        background-color: $attention-red;

        &:hover {
            background-color: rgba($attention-red, 0.9)
        }

        &:active, &:focus {
            background-color: rgba($attention-red, 0.8)
        }
    }
}

.fls-link {
    color: $dark-blue;

    &:hover, &:focus {
        transition: color 0.1s ease-in-out;
        color: $attention-blue;
    }
}