﻿
$header-background-color: $steel-blue-variant-3;

.system-details {
    $footer-height: 0rem;
    $navmenu-height: 6rem;
    //min-height: calc(100vh - ($footer-height + $navmenu-height));
    background-color: $steel-blue-variant-5;

    &-title-band-section {
        top: 0;
        background-color: $header-background-color;
        padding-bottom: 3rem;
        position: relative;
    }

    &-title-section {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding-top: 3rem;
        max-width: $component-max-width;
        margin: auto;
    }

    &-title-container {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 3rem;
    }

    &-content-container {
        max-width: $component-max-width;
        margin: auto;
        width: 100%;
    }

    &-content-section {
        display: flex;
        margin: 0 auto;
        margin-top: 3.5rem;
        padding: 0 4rem;
        height: 100%;
        justify-content: center;
    }
}
