

@keyframes donut-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

@keyframes entry-spinner {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.fls-spinner {
    $spinnerSize :4;
    width: $spinnerSize + rem;
    height: $spinnerSize + rem;
    z-index: 2;
    position: relative;
    animation: donut-spin 1.2s linear infinite;

    circle {
        fill: transparent;
        stroke: $dark-blue;
        stroke-width: 4;
        stroke-linecap: round;
        stroke-dasharray: 70;
        transform-origin: (0.5px * $spinnerSize) (0.5px * $spinnerSize) 0;
        stroke: $dark-blue;
    }

    &__background {
        width: 100%;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
