// Primary colors
    //new variables - with CVI names
$fls-blue: #002A54;
$electric-blue: #004FFF;
$future-green: #0FC580;
$iron-purple: #7B335F;
$gold: #CE9048;
$steel-blue: #82AFBE;

    //old
$dark-blue: $fls-blue;
$turquoise-green: $future-green;
 //do we still need this red?
 //$wineberry-red: #C24B73;
$wineberry-red: $iron-purple;
$dark-magenta: $iron-purple;
$grey-blue: $steel-blue;

// Primary variation colors

    //new variation fls-blue
    //75% tint
$fls-blue-variant-1: #405f7f;
    //50% tint
$fls-blue-variant-2: #7f94a9;
    //30% tint
$fls-blue-variant-3: #b2bfcb;
    //20% tint
$fls-blue-variant-4: #ccd4dd;
    //10% tint
$fls-blue-variant-5: #e5e9ee;

    //old
$dark-blue-variant-1: $fls-blue-variant-1;
$dark-blue-variant-2: $fls-blue-variant-2;
$dark-blue-variant-3: $fls-blue-variant-3;
$dark-blue-variant-4: $fls-blue-variant-4;

    //new variation future-green
    //75% tint
$future-green-variant-1: #4bd4a0;
    //50% tint
$future-green-variant-2: #87e2bf;
    //30% tint
$future-green-variant-3: #b7edd9;
    //20% tint
$future-green-variant-4: #cff3e6;
    //10% tint
$future-green-variant-5: #e7f9f2;

    //old
$turquoise-green-variant-1: $future-green-variant-1;
$turquoise-green-variant-2: $future-green-variant-2;
$turquoise-green-variant-3: $future-green-variant-3;

    //new variation electric-blue
    //75% tint
$electric-blue-variant-1: #407bff;
    //50% tint
$electric-blue-variant-2: #7fa7ff;
    //30% tint
$electric-blue-variant-3: #b2caff;
    //20% tint
$electric-blue-variant-4: #ccdcff;
    //10% tint
$electric-blue-variant-5: #e5edff;


    //old
$blue-variant-1: $electric-blue-variant-1;
$blue-variant-2: $electric-blue-variant-2;
$blue-variant-3: $electric-blue-variant-3;

    //new gold variations 
    //75% tint
$gold-variant-1: #daac76;
    //50% tint
$gold-variant-2: #e6c7a3;
    //30% tint
$gold-variant-3: #f0ddc8;
    //20% tint
$gold-variant-4: #f5e9da;
    //10% tint
$gold-variant-5: #faf4ec;

    //new iron-purple variations
    //75% tint
$iron-purple-variant-1: #9c6687;
    //50% tint
$iron-purple-variant-2: #bd99af;
    //30% tint
$iron-purple-variant-3: #d7c1cf;
    //20% tint
$iron-purple-variant-4: #e5d6df;
    //10% tint
$iron-purple-variant-5: #f2eaef;

    //new variation steel-blue
    //75% tint
$steel-blue-variant-1: #a1c3ce;
    //50% tint
$steel-blue-variant-2: #c0d7de;
    //30% tint
$steel-blue-variant-3: #d9e7eb;
    //20% tint
$steel-blue-variant-4: #e6eff2;
    //10% tint
$steel-blue-variant-5: #f2f7f8;
    //ligther version of variant 5
$steel-blue-variant-6: lighten($steel-blue-variant-5, 3%);
    //darker version of variant 5
$steel-blue-variant-7: darken($steel-blue-variant-5, 2%);

    // old
$grey-blue-variant-1: $steel-blue-variant-1;
$grey-blue-variant-2: $steel-blue-variant-2;

$light-grey-variant-1: #F2F4F6;

$white-variant-1: #FCFCFC;
$white-variant-2: #F8F8F8;

// Secondary colors
$black: #000;
$dark-grey: #2D2D2D;
$light-grey-1: #D4D4D4;
$light-grey-2: #E2E2E2;
$light-grey-3: #EEEEEE;
$white: #fff;


// Attention colors
    //old red kept: unless new CVI red/purple ($iron-purple) wanted instead for attention?
$attention-red: #FF281C;
$attention-red-1: $attention-red;
$attention-red-2: #fa7972;

$attention-yellow: $gold;
$attention-blue: $electric-blue;
$attention-green: $future-green;

//other
$default-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$bottom-box-shadow: rgba(21, 19, 72, 0.4) 0px 15px 6.5px -16px;  
$default-opaque-box-shadow: rgba(24, 27, 67, 0.11) 0px 2px 12px 0px;
$shallow-box-shadow: rgba(99, 99, 99, 0.14) 0px 1px 4px;
$prominent-box-shadow: rgba(21, 19, 72, 0.4) 0px 1px 14px;
$semi-prominent-box-shadow: rgba(21, 19, 72, 0.4) 0px 0.5px 8px;

$fls-blue-border: solid $fls-blue 1px;
$electric-blue-border: solid $electric-blue 1px;


@mixin component-max-width {
    max-width: $component-max-width;
    margin: 0 auto;

    @include media-query('component-max-width') {
        padding: 0 4rem;
    }
}

@mixin wrap-text-with-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}