
.fls-sp-collection {
    min-height: calc(100vh - 12rem);
    margin: 0;

    &__content-section {
        @include component-max-width;
        margin-top: 4rem;
    }

    .fls-sp-detail__heading-wrapper {
        padding-bottom: 2rem;
        padding-top: 2rem;
        margin: 0 auto;
        @include component-max-width;

        p {
            -webkit-line-clamp: 3;
            line-height: 1.4;
        }
    }

    .fls-sp-detail__heading-section {
        min-height: 13.5rem;
        padding-top: 5rem;
    }

    .fls-sp-detail__heading-background-logo {
        bottom: -2rem;
    }

    .fls-sp-search__util-buttons-container {
        @include component-max-width;
        margin-left: -1rem;
    }
}

.fls-part-upload-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed $electric-blue-variant-1;
    margin-top: 25px;
    height: 200px;
    cursor: pointer;
    border-radius: 5px;
}

.fls-part-upload-fileName-text {
    color: $fls-blue;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.7rem;
}


.fls-model-PC--delete:disabled {
    cursor:not-allowed;
    background-color: rgba($attention-red, 0.4);
    border: 1px solid rgba($attention-red, 0.2);
}

.fls-model-PC--primary:disabled {
    cursor: not-allowed;
    background-color: rgba($attention-blue, 0.4);
    border: 1px solid rgba($attention-blue, 0.2);
}

.fls-part-upload-download-template-text {
    color: $fls-blue;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
}

.fls-model-PC__header {
    display:flex;
    justify-content:center;
    align-items:center;
}
.fls-model-PC__header span {
    color: $white;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
}

.fls-part-upload-dialog-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fls-part-upload-error-text {
    color: $attention-red;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
}

.fls-update-status-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.fls-update-status-names {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.fls-update-status-vertical-line {
    border-left: 2px solid #d9e7eb;
    height: 100px;
}

.fls-update-success-close-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
}
.fls-no-data-found {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}