$header-background-color: $steel-blue-variant-3;

.fls-sp-search {

    &__overlay {
        z-index: 3;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: $steel-blue-variant-5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        &-content-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 4rem;
            margin-top: 15rem;
            position: relative;
        }

        &-filter-page-title-link {
            position: relative;
            position: absolute;
            bottom: 0;
            right: 7.5rem;
        }

        &-filter-search-btn {

            &:hover {
                border: $electric-blue-border;
                background-color: $white;

                span {
                    color: $electric-blue;
                }
            }
        }

        &-title {
            color: $fls-blue;
        }

        .fls-text-input {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;

            .fls-button {
                width: 17rem;
                text-transform: uppercase;
                letter-spacing: 0.2rem;
                font-size: 1.5rem;
                font-weigth: ligther;
                margin-left: 7.5rem;
            }

            &__input-field {
                background-color: $white;
            }
        }
        &-logo{
            align-content: center;
            justify-content: center;
            display: flex;
        }
    }


    &__util-buttons-container {
        display: flex;

        button {
            margin-left: 1rem;
        }
    }

    &__input-container {
        padding-top: 3rem;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        gap: 6rem;

        @include media-query('mobile') {
            flex-direction: column;
        }
    }

    &__input-section, &__content-section, &__card-container {
        @include component-max-width;
    }

    &__card-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
    }

    .fls-spinner__background {
        padding-bottom: 20rem;
        padding-top: 16rem;
    }
}
