.fls-data-health-traffic-lights {
    display: flex;
    margin: .7rem;
    align-items: center;

    &__background {
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 50%;
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}