﻿
.fls-navigation {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	height: 8.5rem;

	&__page-title {
		font-size: 2rem;
		color: $fls-blue;
		align-self: center;
		padding-left: 2rem;
		width: fit-content;
		white-space: nowrap;

		&-link {
			text-decoration: none;
			display: flex;
			align-items: center;
		}
	}

	&__title-container {
		display: flex;
	}

	&__container {
		width: 80%;
		max-width: $component-max-width;
		height: 100%;
		max-height: 6rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		z-index: 10;

		&--alert {
			display: flex;
			margin-left: 1rem;
			height: fit-content;
			margin-left: 3rem;
			padding: 1.4rem 2.5rem;
			color: $white;
			background-color: $iron-purple;
			font-family: ProximaNovaBold,Arial,sans-serif;
			font-size: 1.5rem;
			font-weight: 700;
			width: 20rem;

			& span {
				white-space: nowrap;
			}
		}
	}

	&__nav-items {
		display: flex;
		margin-left: 4rem;
		margin-right: 1rem;
	}

	&__nav-item {
		margin: 0 2rem;
		color: $fls-blue;
		position: relative;
		text-decoration: none;

		&--active {
			color: $attention-blue;

			&:before {
				content: "";
				height: 2px;
				width: 100%;
				position: absolute;
				bottom: -0.4rem;
				left: 0;
				background-color: $attention-blue;
			}
		}

		&:hover {
			color: $electric-blue-variant-1;
		}
	}

	&__logo-wrapper {
		display: flex;
		align-items: center;
		gap: 2rem;
	}
}
