
.fls-tabs-selector {
    width: fit-content;
    position: relative;

    &:hover {
        border: $electric-blue-border;

        p {
            color: $electric-blue;
        }
    }

    &__plus {
        color: $fls-blue;
    }

    &__selected {
        padding: 0 2rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
    }

    &__content-container {
        top: 5.5rem;
        right: 0;
        box-shadow: $prominent-box-shadow;
        background-color: $white;
        position: absolute;
        z-index: 10;
        min-width: 100%;
        width: fit-content;
        padding-right: 1rem;

        &:before {
            box-shadow: $default-box-shadow;
            content: "";
            top: -0.5rem;
            position: absolute;
            width: 1rem;
            height: 1rem;
            right: 20%;
            background-color: $white;
            transform: rotate(135deg);
        }

        &:after {
            content: "";
            top: 0.01rem;
            position: absolute;
            width: 3rem;
            height: 1rem;
            right: 20%;
            background-color: $white;
        }
    }

    &__content-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__content-list-item {
        padding: 0.85rem 1.5rem;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        white-space: nowrap;
        width: fit-content;
        text-transform: capitalize;

        &--selected {
            color: $electric-blue-variant-1;
        }

        &:hover {
            color: $electric-blue;
        }

        &:first-child {
            padding: 1.5rem 1.5rem 0.85rem 1.5rem;
        }

        &:last-child {
            padding: 0.85rem 1.5rem 1.5rem 1.5rem;
        }
    }

    &__plus {
        height: 100%;
        font-size: 3rem;
        font-weight: bolder;
        transform: rotate(45deg);
        color: $electric-blue;

        &--rotated {
            transform: rotate(90deg);
            color: $fls-blue;
        }
    }
}
