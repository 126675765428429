
.fls-model-PC {
    text-decoration: none;
    border: none;
    padding: 1.4rem 1.5rem;
    color: $fls-blue;
    font-family: 'ProximaNovaBold', Arial, sans-serif;
    font-weight: bold;
    font-size: 1.6rem;
    cursor: pointer;
    height: fit-content;
    border: 1px solid $dark-blue;
    background-color: $light-grey-2;

    &__icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        padding-right: .5rem;
    }

    &:disabled {
        span, div {
            opacity: 0.4;
        }

        cursor: default;
    }

    &--primary {
        color: $white;
        border: 1px solid $attention-blue;
        background-color: $attention-blue;
        width: 100%;
        font-weight: bold;
        font-size: 1.6rem;

        &:disabled {
            background-color: $electric-blue-variant-3;
        }

        &:hover {
            background-color: $electric-blue-variant-2
        }

        &:active, &:focus {
            background-color: $electric-blue-variant-1
        }
    }

    &__header {
        padding: 0.1rem 0.1rem;
        color: $white;
        border: 1px solid $dark-blue;
        background-color: $dark-blue;
        text-align: center;
        font-size: 1.9rem;
    }

    &--primary-with-icon {
        color: $white;
        border: 1px solid $electric-blue;
        background-color: $electric-blue;
        display: flex;
        align-items: center;

        &:disabled {
            border: 1px solid $electric-blue-variant-3;
            background-color: $electric-blue-variant-3;

            &:hover {
                background-color: $electric-blue-variant-3;
            }
        }

        &:hover {
            background-color: $electric-blue-variant-2
        }

        &:active, &:focus {
            background-color: $electric-blue-variant-1
        }
    }

    &--primary-with-icon-settings {
        border: none;
        background-color: $steel-blue-variant-5;
        display: flex;
        white-space: nowrap;
        align-items: center;
        padding: 0;

        &:hover {
            color: $electric-blue-variant-1;
        }

        &:active, &:focus {
            color: $electric-blue-variant-1;
            background-color: $steel-blue-variant-5
        }
    }

    &--primary-with-icon-outline {
        color: $fls-blue;
        border: 1px solid $fls-blue;
        background-color: $white;
        display: flex;
        align-items: center;

        div svg path {
            stroke: $fls-blue;
        }

        &:disabled {
            border: 1px solid $electric-blue-variant-5;
            background-color: $electric-blue-variant-5;

            &:hover {
                border: 1px solid $electric-blue-variant-5;
                background-color: $electric-blue-variant-5;

                span {
                    color: $fls-blue;
                }

                div svg path {
                    stroke: $fls-blue;
                }
            }
        }

        &:hover {
            border: $electric-blue-border;
            background-color: $white;

            span {
                color: $electric-blue;
            }

            div svg path {
                stroke: $electric-blue;
            }
        }

        &:active, &:focus {
            background-color: $steel-blue-variant-5;
        }
    }

    &--secondary {
        color: $white;
        border: 1px solid $dark-blue;
        background-color: $dark-blue;

        &:hover {
            background-color: $dark-blue-variant-2
        }

        &:active, &:focus {
            background-color: $dark-blue-variant-1
        }
    }

    &--confirm {
        color: $white;
        //changed to attention-blue from prior attention-green
        border: 1px solid $attention-blue;
        background-color: $attention-blue;

        &:hover {
            background-color: rgba($attention-blue, 0.9)
        }

        &:active, &:focus {
            background-color: rgba($attention-blue, 0.8)
        }
    }

    &--action {
        &:active, &:focus {
            background-color: $white;
        }
    }

    &--delete, &--cancel {
        color: $white;
        border: 1px solid $attention-red;
        background-color: $attention-red;
        width: 100%;
        font-weight: bold;
        font-size: 1.6rem;

        &:hover {
            background-color: rgba($attention-red, 0.9)
        }

        &:active, &:focus {
            background-color: rgba($attention-red, 0.8)
        }
    }
}

.fls-link {
    color: $dark-blue;

    &:hover, &:focus {
        transition: color 0.1s ease-in-out;
        color: $attention-blue;
    }
}

.fls-text-input-PC {
    display: flex;
    min-width: 50rem;

    button {
        margin-left: 1rem;
    }

    &__input-container-PC {
        background-color: $white;
        width: 100%;
        display: flex;
        margin-right: 1rem;
        height: 4.5rem;
        position: relative;

        &--regular {
            display: block;
            background-color: transparent;
            border: none;
            margin-right: 0;
            padding: 2.5rem 2.5rem;

            .fls-text-input__input-field {
                box-sizing: border-box;
                height: 4.4rem;
                margin-top: 0.5rem;
                padding: 0 1rem;
                background-color: $white;
                border: 1px solid $light-grey-1;
            }
        }
    }

    &__icon {
        top: 0.9rem;
        position: absolute;
        padding: 0 1rem;
        right: 0;
    }

    &__center {
        padding: 12px 0
    }

    &__label-field-PC {
        padding-left: 1rem;
        width: 50%;
        background-color: transparent;
        border: none;
        display: flex;
    }

    &__input-field-PC {
        padding-left: 2rem;
        width: 47%;
        background-color: transparent;
        border: none;
        display: flex;
        border: 1px solid $light-grey-1;

        &:focus {
            outline: none;
            color: $dark-blue;
            border: 1px solid $grey-blue-variant-2;
        }

        &:hover {
            color: $dark-blue;
            border: 1px solid $grey-blue-variant-2;
        }
    }
}

.fls-data-tablePC {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 3rem;
    box-shadow: $shallow-box-shadow;

    &__caption {
        color: $dark-blue;
        padding: 0 0 1rem 1.2rem;
        text-align: left;
        font-size: 17px;
    }

    &__row {

        &:hover td {
            color: $electric-blue;
        }

        &:nth-child(even) {
            background-color: $light-grey-variant-1;
        }

        &:nth-last-child(1) {
            border-bottom: $table-data-border;
        }

        background-color: $white-variant-1;
    }

    &__buttonalignment {
        text-align: right;
        align-items: flex-end;
        align-items: flex-end;
    }

    &__cell {
        padding: 1.4rem 2rem 1.4rem 0;

        &:last-child {
            text-align: left;
            padding-right: 1.6rem;
        }

        &:first-child {
            padding-left: 1.6rem;
        }
    }

    &__header {
        color: $white;
        font-weight: bold;
        font-family: 'ProximaNovaBold', Arial, sans-serif;
        font-size: 1.3rem;
        background-color: $dark-blue;
        text-align: left;
    }

    &__cell {
        padding: 1.2rem 2rem 1.2rem 0;

        &:last-child {
            text-align: left;
            padding-right: 1.6rem;
            border-right: $table-data-border;
        }

        &:first-child {
            border-left: $table-data-border;
        }

        &--button-container {
            .fls-data-table__edit-button-container {
                justify-content: end;
            }
        }

        &--align-right {
            text-align: right;
        }

        &--nobreak {
            white-space: nowrap;
        }
    }

    &__edit-button {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: flex-end;
        padding: 0.5rem 1.5rem;
        text-transform: capitalize;
        background-color: $grey-blue-variant-1;
        border-radius: 0.4rem;
        border: none;
        margin-left: 1rem;
        justify-content: flex-end;

        &:hover {
            background-color: $blue-variant-3;
        }

        p {
            padding-left: 0.65rem;
        }

        span {
            width: 1.8rem;
            height: auto;
        }
    }

    &__edit-button-container {
        display: flex;
    }

    &__edit-button-mini-modal {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: fit-content;
        border-radius: .4rem;
        box-shadow: $prominent-box-shadow;
        background-color: $white;

        &--active {
            display: block
        }
    }

    &__edit-button-mini-modal-container {
        display: flex;
        align-items: stretch;
    }

    &__edit-button-mini-modal-item {
        cursor: pointer;
        width: 100%;
        padding: 1rem 2.5rem;
        background-color: none;
        border: none;
        text-transform: capitalize;
        display: flex;
        align-items: flex-end;
        position: relative;
        flex-direction: row;
        position: relative;

        p {
            padding-left: 0.5rem;
        }

        &:hover {
            background-color: $grey-blue-variant-1;
        }

        span {
            width: 2rem;
            height: 2rem
        }

        &:first-child {
            border-radius: .4rem .4rem 0 0;
        }

        &:last-child {
            border-radius: 0 0 .4rem .4rem;
        }

        &--delete {
            background-color: $attention-red;

            p {
                color: $white;
            }

            &:hover {
                background-color: rgba($attention-red, 0.9)
            }

            &:active, &:focus {
                background-color: rgba($attention-red, 0.8)
            }
        }
    }
}

::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
}
