
.fls-sp-card {
    height: 5.5rem;
    border-radius: .6rem;
    cursor: pointer;
    text-decoration: none;
    background-color: $white;
    box-shadow: $default-opaque-box-shadow;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    min-width: 0;
    position: relative;

    &__wrapper {
        display: flex;
        width: 100%;
        height: 4.9rem;
    }

    .fls-data-health-traffic-lights {
        margin: 0;
    }

    &:hover {

        &::before {
            border: $electric-blue-border;
            content: " ";
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            border-radius: .6rem;
            pointer-events: none;
        }

        span {
            color: $electric-blue-variant-1;
        }

        h2, p {
            color: $electric-blue;
        }
    }

    &__content-container, &__secondary-info {
        position: relative;
        padding: 1.5rem 2rem;
        display: flex;
    }

    &__primary-info {
        padding: 1rem 2rem;
        margin-bottom: -0.75rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: .6rem 0 0 .6rem;
        border-right: 0.1rem solid $fls-blue-variant-3;

        &-wrapper {
            display: flex;
            gap: -0.5rem;
            margin-right: 2rem;
        }

        span {
            color: $fls-blue-variant-2;
            opacity: 0.7;
        }

        article {
            margin-bottom: auto;
        }

        h2 {
            color: $dark-blue;
            margin: 0;
            width: max-content;
            margin-right: 3rem;
            font-size: 18px;
        }

        p {
            color: $dark-blue-variant-2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
            -webkit-box-orient: vertical;
            flex-shrink: 1;
            text-align: right;
        }
    }

    &__secondary-info {
        display: flex;
        margin: 0;
        border-radius: 0 .6rem .6rem 0;
        width: 50%;
    }

    &__secondary-info-list-item {
        width: 50%;

        span {
            color: $dark-blue-variant-1;
            opacity: 0.7;
        }

        p {
            min-height: 1.4rem;
            color: $dark-blue;
            @include wrap-text-with-ellipsis;
        }
    }

    &__data-health-hover {
        border: $electric-blue-border;
        border-radius: .6rem;
        background-color: $white;
        content: " ";
        display: block;
        pointer-events: none;
        box-shadow: $semi-prominent-box-shadow;
        min-width: 16rem;
        padding: 2rem;
        margin-right: auto;
        overflow: auto;

        .fls-data-health-traffic-lights {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
