

@font-face {
    font-family: "ProximaNovaRegular";
    font-weight: 400;
    src: url("./../fonts/ProximaNovaRegular.otf") format("opentype")
}

@font-face {
    font-family: "ProximaNovaBold";
    font-weight: 700;
    src: url("./../fonts/ProximaNovaBold.otf") format("opentype")
}

@font-face {
    font-family: "ProximaNovaLight";
    font-weight: 300;
    src: url("./../fonts/ProximaNovaLight.otf") format("opentype")
}
