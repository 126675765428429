.fls-ext-card {
    height: 5.5rem;
    border-radius: .6rem;
    cursor: pointer;
    text-decoration: none;
    background-color: $white;
    box-shadow: $default-opaque-box-shadow;
    transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
    min-width: 0;
    position: relative;

    &__wrapper {
        display: flex;
        width: 100%;
        height: 4.9rem;
        padding-left: 5%;
    }

    &__link-info {
        @include fluid-size('font-size', 25, 25, $mobile, $desktop);
        width: 28%;
        align-items: center;
        font-weight: 400;
        font-family: 'ProximaNovabold', Arial, sans-serif;
        padding: 1rem 4rem;
        background-color: $fls-blue-variant-5;
        /*color: $dark-blue;*/
        height: 60px;
        cursor: pointer;
        color: blue;
        text-decoration-line: underline;
    }

    &__detail-info {
        @include fluid-size('font-size', 20, 20, $mobile, $desktop);
        width: 77%;
        font-weight: 400;
        font-family: 'ProximaNovabold', Arial, sans-serif;
        padding: 1.5rem 2rem;
        background-color: $steel-blue-variant-3;
        color: $dark-blue;
        height: 60px;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        text-align: -webkit-match-parent;
        justify-content: center;
    }

    &__mail-detail-info {
        @include fluid-size('font-size', 20, 20, $mobile, $desktop);
        width: 77%;
        font-weight: 400;
        font-family: 'ProximaNovabold', Arial, sans-serif;
        padding: 1.5rem 2rem;
        background-color: $steel-blue-variant-3;
        color: $dark-blue;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    &__empty-row {
        height: 25px;
    }

    &__template {
        line-height: 2.0em;
    }
}
