

.fls-in-page-error {
    width: 100%;
    padding: 4rem;
    box-sizing: border-box;

    &__wrapper {
        max-width: $component-max-width;
        padding: 4rem 0;
        display: flex;
        align-items: center;
        justify-content: left;
        box-shadow: $default-box-shadow;
        border-radius: .4rem;
        background-color: $white;
        margin: 0 auto;
    }

    &__icon {
        margin-bottom: 2rem;
        margin-left: -0.5rem;
        display: flex;
        align-items: center;
    }
    
    &__button-container {
        margin-top: 5rem;
        
        .fls-button {
            margin-right: 1rem;
        }
    }
    
    &__container {
        color: $dark-blue;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 6rem;

        .fls-font__medium-bold {
            color: $dark-blue;
        }

        a {
            width: fit-content;
            margin-top: 5rem;
        }
    }

}

.fls-sp-detail, .fls-sp-collection, .fls-sp-search {
    .fls-in-page-error {
        margin-top: -10rem;
        position: relative;
        z-index: 2;
    }
}

.fls-sp-filter__container {
    .fls-in-page-error {
        padding: 0;
    }
}