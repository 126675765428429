.fls-allow-portal-modal {
  &__excel-result {
    margin-left: 25px;
    &__line {
      display: flex;
      > label {
        width: 25rem;
        padding-top: 12px;
        font-size: 24px;
      }
      > label:first-child {
        border-right: 1px solid #ccc;
      }
      > label:last-child {
        margin-left: 50px;
      }
    }
    &__spinner {
      margin-left: 45%;
    }
  }
}
