


$component-max-width: 1600px; 
$mobile: 768;
$desktop: 1280;

$breakpoints-map: (
    'component-max-width': 1620px,
    'mobile': 768px,
    'tablet': 1024px,
    'desktop': 1280px
);

 
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints

@mixin media-query($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints-map, $breakpoint) {
        // Prints a media query based on the value
        @media (max-width: map-get($breakpoints-map, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints-map)}.";
    }
}
